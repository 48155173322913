<template>
  <div class="home">
    <div class="main">
      <div class="left">
        <div class="title">OBS开播如此简单"</div>
        <div class="h2">
          <div>告别复杂直播设置</div>
          <img src="../assets/Frame.png" alt="" />
          <div>实现高质量直播</div>
        </div>
        <div class="button" @click="handleOpen">下载源调试</div>
        <div class="tip">(目前仅支持Windows)</div>
      </div>
      <div class="icon">
        <img src="../assets/向下3.svg" alt="" />
      </div>
      <div class="right">
        <img src="../assets/Group 495.png" alt="" />
      </div>
    </div>
    <div class="download">
      <div class="title">软件下载</div>
      <div class="enTitle">Software Download</div>
      <div class="downloadList">
        <div
          class="downloadItem"
          v-for="item in ServiceData"
          :key="item.id"
          @click="download(item)"
        >
          <div class="left">
            <div class="title">{{ item.title }}</div>
            <div class="description">{{ item.description }}</div>
            <div class="describe">{{ item.describe }}</div>
          </div>
          <div class="img">
            <img :src="item.img_src" alt="" />
          </div>
        </div>
      </div>
      <div class="briefIntroduction">
        <div class="title">智能多彩的直播效果</div>
        <div class="enTitle">
          Intelligent and colorful live broadcast effects
        </div>
        <div class="tab">
          <div
            :class="briefIntroduction.id == 4 ? 'action' : ''"
            @click="changeTab(4)"
          >
            免费客服
          </div>
          <div
            :class="briefIntroduction.id == 2 ? 'action' : ''"
            @click="changeTab(2)"
          >
            OBS一键调试
          </div>
          <div
            :class="briefIntroduction.id == 3 ? 'action' : ''"
            @click="changeTab(3)"
          >
            音效软件
          </div>
          <div
            :class="briefIntroduction.id == 1 ? 'action' : ''"
            @click="changeTab(1)"
          >
            自动推流
          </div>
        </div>
        <div class="briefIntroductionInfo">
          <div class="backgroundColor">
            <div class="title">{{ briefIntroduction.title }}</div>
            <div class="text">
              {{ briefIntroduction.text }}
            </div>
          </div>
          <div class="img">
            <img :src="briefIntroduction.img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 备案号 -->
    <div class="recordNumber">鄂ICP备20239234</div>
    <!-- 悬浮 -->
    <div class="suspension">
      <div class="wx">
        <div>企业微信</div>
        <img
          src="https://resource.yuantiaoshi.com/officialWebsite/code.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ServiceData: [
        {
          id: 1,
          img_src: "https://resource.yuantiaoshi.com/officialWebsite/logo.png",
          title: "源调试",
          upload: "",
          description: "最新版",
          describe: "告别复杂直播设置 实现高质量直播",
        },
        {
          id: 2,
          img_src:
            "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
          title: "OBS",
          description: "28.0.2版本",
          upload: "https://resource.yuantiaoshi.com/OBS-Studio-28.0.2-x64.exe",
          describe: "用于视频录制和直播的免费开源软件",
        },
        {
          id: 5,
          img_src:
            "https://p9-arcosite.byteimg.com/tos-cn-i-goo7wpa0wc/57454a8aeb2041a887a149c0674f83d7~tplv-goo7wpa0wc-image.image",
          title: "直播伴侣",
          description: "直达官网",
          upload: "https://streamingtool.douyin.com/",
          describe: "抖音直播伴侣",
        },
        {
          id: 4,
          img_src:
            "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
          title: "OBS",
          description: "30.1.2版本",
          upload: "https://resource.yuantiaoshi.com/OBS%2030.exe",
          describe: "用于视频录制和直播的免费开源软件",
        },

        {
          id: 3,
          img_src:
            "https://resource.yuantiaoshi.com/officialWebsite/OBS_Studio_Logo.png",
          title: "OBS",
          description: "29.1.2版本",
          upload: "https://resource.yuantiaoshi.com/OBS%2029.exe",
          describe: "用于视频录制和直播的免费开源软件",
        },
        {
          id: 6,
          img_src: "https://resource.yuantiaoshi.com/officialWebsite/zi.png",
          title: "字体搬运工",
          description: "直达官网",
          upload: "https://font.sucai999.com",
          describe: "免费商用字体搬运工",
        },
      ],
      briefIntroduction: {
        id: 4,
        title: "免费客服",
        text: "暖心客服，有任何问题找客服解决",
        img: require("../assets/1726715386994929.png"),
      },
      briefIntroductionList: [
        {
          id: 1,
          title: "自动推流",
          text: "打开自动操作，只需点击直播伴侣开启直播 便可完成推流，方便简单",
          img: require("../assets/Frame445.png"),
        },
        {
          id: 2,
          title: "OBS一键调试",
          text: "一键保存或调试OBS直播设备",
          img: require("../assets/1726713981318229.png"),
        },
        {
          id: 3,
          title: "音效软件",
          text: "丰富的默认音乐，也可自定义上传音乐支持一件播放",
          img: require("../assets/Frame 444.png"),
        },
        {
          id: 4,
          title: "免费客服",
          text: "暖心客服，有任何问题找客服解决",
          img: require("../assets/1726715386994929.png"),
        },
      ],
    };
  },
  async mounted() {
    try {
      await axios.post("/api1/index/visitOfficialWeb");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async handleOpen() {
      const upload = await axios.get("/api");
      window.open(upload.data.dict_remark);
      // window.open(upload)
    },
    uploadObs(type) {
      if (type == 28) {
        window.open(
          "https://resource-1317007202.cos.ap-shanghai.myqcloud.com/OBS-Studio-28.0.2-x64.exe"
        );
      } else if (type == 29) {
        window.open(
          "https://resource-1317007202.cos.ap-shanghai.myqcloud.com/OBS%2029.exe"
        );
      } else if (type == 30) {
        window.open(
          "https://resource-1317007202.cos.ap-shanghai.myqcloud.com/OBS%2030.exe"
        );
      }
    },
    changeTab(type) {
      this.briefIntroduction = this.briefIntroductionList.find(
        (item) => item.id == type
      );
    },
    // 下载源调试
    download(item) {
      if (item.id == 1) {
        this.handleOpen();
      } else {
        window.open(item.upload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
}

.home {
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
.main {
  height: 440px;
  width: 100%;
  background-image: url("../assets/鲸落素材-白色.jpg");
  background-size: 100% 100%;
  // overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;
  .left {
    margin-left: 85px;
    margin-top: 126px;
    .title {
      font-weight: 700;
      font-size: 56px;
      color: #000;
    }
    .h2 {
      font-size: 20px;
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-top: 12px;
      img {
        width: 26px;
        height: 8px;
        margin: 0 8px;
      }
      div {
        border-bottom: 1px solid #000;
      }
    }
    .button {
      width: 230px;
      height: 78px;
      background: #404565;
      border-radius: 12px;
      text-align: center;
      line-height: 78px;
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      margin-top: 24px;
      cursor: pointer;
    }
    .tip {
      font-size: 16px;
      font-weight: 400;
      margin: 12px 0 0 24px;
    }
  }
  .icon {
    position: absolute;
    width: 68px;
    height: 68px;
    bottom: -34px;
    left: calc(50vw - 34px);
    background: #fff;
    border-radius: 50%;
    box-shadow: 2px 2px 5px #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 34px;
      height: 40px;
    }
  }
  .right {
    margin-right: 85px;
    margin-top: 86px;
    img {
      width: 560px;
      height: 353px;
    }
  }
}
.download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .title {
    font-size: 40px;
    font-weight: 700;
  }
  .enTitle {
    font-size: 24px;
    margin-top: 4px;
  }
  .downloadList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    width: 90%;
    margin: auto;
    .downloadItem {
      width: 30%;
      height: 180px;
      background-image: url("../assets/鲸落素材-白色.jpg");
      background-size: 100% 100%;
      border-radius: 16px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 28px;
      box-sizing: border-box;
      cursor: pointer;
      .left {
        .title {
          font-size: 24px;
        }
        .description {
          font-size: 18px;
        }
        .describe {
          margin-top: 32px;
          font-size: 18px;
        }
      }
      img {
        width: 114px;
        height: 114px;
      }
    }
  }
}
.briefIntroduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  .tab {
    display: flex;
    margin-top: 32px;
    width: 100%;
    justify-content: center;
    div {
      padding: 0 18px;
      height: 40px;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      border-radius: 50px;
      cursor: pointer;
      margin: 0 8px;
    }
    .action {
      background: #404565;
      color: #fff;
    }
  }
  .briefIntroductionInfo {
    width: 1120px;
    height: 366px;
    margin-top: 72px;
    position: relative;
    display: flex;
    .backgroundColor {
      width: 1094px;
      height: 308px;
      background: #404565;
      border-radius: 30px;
      padding-left: 540px;
      box-sizing: border-box;
      color: #fff;
      .title {
        font-size: 32px;
        border-bottom: 4px solid #fff;
        display: inline-block;
        padding-bottom: 10px;
        margin-top: 50px;
        margin-left: 70px;
      }
      .text {
        margin-top: 86px;
        font-size: 24px;
        margin-left: 70px;
        width: 456px;
      }
    }
    .img {
      position: absolute;
      left: -20px;
      top: -30px;
      width: 540px;
      height: 366px;
      backdrop-filter: blur(30px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 30px;

      img {
        width: 488px;
        height: 330px;
        border-radius: 30px;
      }
    }
  }
}

.suspension {
  position: fixed;
  top: 500px;
  left: 65px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-image: url("../assets/Frame 439.png");
  background-size: 100% 100%;
  box-shadow: 0px 0px 3px #000;
  &:hover {
    .wx {
      display: flex;
    }
  }
  .wx {
    position: fixed;
    top: 450px;
    left: 165px;
    width: 220px;
    height: 160px;
    border-radius: 9px;
    background: #fff;

    flex-direction: column;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px #000;
    display: none;
    div {
      font-size: 18px;
      color: #404565;
    }
    img {
      margin-top: 12px;
      width: 100px;
      height: 100px;
    }
  }
}
.recordNumber {
  width: 100%;
  height: 88px;
  text-align: center;
  line-height: 88px;
  font-size: 16px;
  background: #404565;
  color: #fff;
  margin-top: 180px;
  text-decoration: underline 2px solid #fff;
}
</style>